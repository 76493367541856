import { FC, useContext, useState } from "react";
import {
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  Collapse
} from "react-bootstrap";
import { Context } from "../../../Config/LocalizationWrapper";

import HeaderLogo from "./_logo";
import GlobalMenu from "./_global_menu";
import NavMenu from "./_menu";
import RegionLanguage from "./_region_language";
import TopMenu from "./_top_menu";

interface Props {
  updateTheme: (language: string) => void;
}

const Header: FC<Props> = ({ updateTheme }) => {
  const [open, setOpen] = useState(false);
  const context = useContext(Context);
  const [language, setLanguage] = useState(
    context?.locale ? context.locale : "en"
  );
  const [expanded, setExpanded] = useState(false);
  const onSelect = (eventKey: any) => {
    setLanguage(eventKey);
    if (context?.locale) {
      context.selectLanguage(eventKey);
      updateTheme(eventKey);
    }
  };
  const logoHandler = () => {
    if (expanded) setExpanded(false);
  };

  return (
    <>
      <div id="top"></div>
      <header className="header fixed-top">
        <Container fluid>
          <Row>
            <Col lg={5} md={12} xs={12} className="logo">
              <Navbar.Brand onClick={logoHandler}>
                <HeaderLogo language={language} />
              </Navbar.Brand>
            </Col>
            <Col lg={7} md={12} xs={12} className="menu-area">
              <Collapse in={open}>
                <div id="mega-menu" className="mega-menu">
                  <TopMenu onSelect={onSelect} onClose={() => setOpen(!open)} language={language} />
                </div>
              </Collapse>
              <Navbar
                expand="lg"
                expanded={expanded}
                onToggle={(expanded) => setExpanded(expanded)}
              >
                <Navbar.Toggle aria-controls="toggle-navbar-nav" />
                <Navbar.Collapse
                  className="justify-content-end my-menu"
                  id="toggle-navbar-nav"
                >
                  <Nav>
                    <div className="mobile-language-area">
                      <RegionLanguage onSelect={onSelect} />
                    </div>
                    <NavMenu language={language} setExpanded={setExpanded} />
                    <div onClick={() => setOpen(!open)} aria-controls="mega-menu">
                      <GlobalMenu language={language} onSelect={onSelect} />
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};
export default Header;
