import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Container } from 'react-bootstrap';

import EarthImage from "../../../assets/images/earth.webp";
import SimpleImage from '../../Shared/Media/SimpleImage';

import {
    ACTIVE_REGIONS,
    AMERCIAS_KEY,
    EUROPE_KEY,
    ASEAN_KEY,
    AFRICA_KEY,
    NML_KEY,
    AUSTRALIA_NEWZEALAND_KEY,
    MIDDLE_EAST_KEY
} from "../../../utils/_active_regions";
import Heading from '../../Shared/Common/Heading'
import { useLocation } from 'react-router-dom';

const regions = [
    { id: AFRICA_KEY },
    { id: ASEAN_KEY },
    { id: EUROPE_KEY },
    { id: MIDDLE_EAST_KEY },
    { id: AMERCIAS_KEY },
    { id: AUSTRALIA_NEWZEALAND_KEY },
    { id: NML_KEY }
];


const MENU_ITEM = ({ regionId, handleNavigation }: any) => {
    const regionItem = ACTIVE_REGIONS.find(x => x.region === regionId);

    const HANDLE_MENU_LINK = (regionId: string, language: string) => {
        handleNavigation(regionId, language);
    }

    return (
        <div className="region-row">
            <p>
                <FormattedMessage id={regionItem?.name_fm} />
            </p>
            <p>
                <span onClick={() => HANDLE_MENU_LINK(regionId, "en")}>English</span>
                {
                    regionItem?.translation && (<>&nbsp;&nbsp;<span onClick={() => HANDLE_MENU_LINK(regionId, "ja")}>日本語</span></>)
                }
            </p>
        </div>
    )
}
interface Props {
    language: string;
    onSelect: (eventKey: any) => void;
    onClose: () => void;
}

const TopMenu: FC<Props> = ({ onSelect, onClose, language }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams();
    queryParams.append("lang", "ja");


    const handleNavigation = (regionId: string, lang: string): void => {
        const region = ACTIVE_REGIONS.find(x => x.region === regionId);
        const pathName = region?.pathName || "/";
        const isRootOrNml = regionId === '/' || regionId === 'nml';
        const isSamePath = location.pathname === pathName;
        if (isRootOrNml) {
            if (isSamePath) {
                onSelect(lang);
                return;
            }
            if (lang === 'en') {
                onSelect(lang);
            } else {
                localStorage.setItem('locale', 'ja');
            }
            window.open(region?.value || "/", '_blank');
            return;
        }

        if (lang === 'en' && !isSamePath) {
            onSelect(lang);
            window.open(region?.value || "/", '_blank');
        }
        onClose();
    }

    return (
        <div className='mega-menu-container'>
            <Container>
                <Heading type='section-sub-heading'>
                    Explore Our Regions
                </Heading>
                <Row>
                    <Col md={6}>
                        {regions.slice(0, 4).map((region, index) => (
                            <MENU_ITEM key={index} regionId={region.id} handleNavigation={handleNavigation} />
                        ))}
                    </Col>
                    <Col md={6}>
                        {regions.slice(4).map((region, index) => (
                            <MENU_ITEM key={index} regionId={region.id} handleNavigation={handleNavigation} />
                        ))}
                    </Col>
                </Row>
                <div className='text-center'>
                    <Heading type='detailed-sub-heading'>
                        <SimpleImage src={EarthImage} /> Nissan Global
                    </Heading>
                    <span className="hyperlink" onClick={() => handleNavigation("/", "en")}>English</span>&nbsp;&nbsp;&nbsp;
                    <span className="hyperlink" onClick={() => handleNavigation("/", "ja")}>日本語</span>
                </div>
            </Container>
        </div>
    )
}
export default TopMenu;